import Title from "./Title";
import ReactCountryFlag from "react-country-flag";
import Pulse from "./Pulse";
import { t } from "i18next";

const YEARS = [
	2001, 2002, 2004, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
	2017, 2018, 2019, 2020, 2022, 2023, 2024,
];

const NATIONS = [
	"ua",
	"lt",
	"lv",
	"ru",
	"by",
	"pl",
	"fi",
	"ee",
	"se",
	"us",
	"kz",
	"mn",
	"no",
	"bg",
	"ca",
	"cz",
	"ro",
	"md",
	"jp",
];

const NAMES = {
	ua: "Ukraine",
	lt: "Lithuania",
	lv: "Latvia",
	ru: "Russia",
	by: "Belarus",
	pl: "Poland",
	fi: "Finland",
	ee: "Estonia",
	se: "Sweden",
	us: "United States of America",
	kz: "Kazakhstan",
	mn: "Mongolia",
	no: "Norway",
	bg: "Bulgaria",
	ca: "Canada",
	cz: "Czech Republic",
	ro: "Romania",
	md: "Moldova",
	jp: "Japan",
};

const DATA = {
	2001: {
		ua: 5,
		lv: 2,
		ru: 1,
		by: 4,
		fi: 7,
		se: 3,
		ca: 6,
	},
	2002: {
		ua: 7,
		lt: 3,
		lv: 2,
		ru: 5,
		by: 4,
		fi: 1,
		ee: 8,
		se: 6,
	},
	2004: {
		ua: 7,
		lt: 4,
		lv: 1,
		ru: 2,
		by: 6,
		pl: 10,
		fi: 5,
		ee: 9,
		se: 3,
		ca: 8,
	},
	2007: {
		ua: 6,
		lt: 2,
		lv: 1,
		ru: 5,
		by: 4,
		pl: 3,
		fi: 9,
		ee: 7,
		se: 8,
		kz: 10,
		cz: 11,
	},
	2008: {
		ua: 6,
		lt: 2,
		lv: 3,
		ru: 1,
		by: 5,
		pl: 4,
		fi: 10,
		ee: 7,
		se: 8,
		bg: 9,
		cz: 11,
	},
	2009: {
		ua: 1,
		lt: 5,
		lv: 2,
		ru: 3,
		by: 8,
		pl: 4,
		fi: 10,
		ee: 9,
		se: 7,
		us: 11,
		bg: 6,
	},
	2010: {
		ua: 10,
		lt: 4,
		lv: 5,
		ru: 3,
		pl: 2,
		fi: 7,
		ee: 8,
		se: 9,
		us: 1,
		kz: 6,
		ca: 11,
	},
	2011: {
		ua: 1,
		lt: 4,
		lv: 3,
		ru: 6,
		by: 5,
		pl: 2,
		fi: 9,
		ee: 7,
		se: 10,
		us: 8,
		kz: 11,
		bg: 12,
		cz: 14,
		md: 13,
	},
	2012: {
		ua: 2,
		lt: 5,
		lv: 4,
		ru: 6,
		by: 1,
		pl: 3,
		fi: 10,
		ee: 11,
		se: 8,
		us: 9,
		kz: 7,
	},
	2013: {
		ua: 4,
		lt: 8,
		ru: 1,
		pl: 9,
		fi: 2,
		ee: 10,
		se: 5,
		us: 3,
		kz: 6,
		mn: 7,
		jp: 11,
	},
	2014: {
		ua: 1,
		lt: 5,
		lv: 10,
		ru: 3,
		by: 2,
		pl: 4,
		fi: 6,
		ee: 7,
		se: 11,
		us: 12,
		kz: 9,
		mn: 13,
		bg: 8,
		cz: 14,
	},
	2015: {
		ua: 1,
		lt: 6,
		lv: 4,
		ru: 3,
		by: 5,
		pl: 10,
		fi: 2,
		ee: 9,
		se: 12,
		us: 11,
		kz: 7,
		mn: 8,
		no: 13,
	},
	2016: {
		ua: 3,
		lt: 2,
		lv: 5,
		by: 1,
		pl: 4,
		fi: 6,
		se: 8,
		us: 9,
		mn: 7,
		no: 10,
	},
	2017: {
		ua: 1,
		lt: 4,
		lv: 2,
		ru: 3,
		by: 6,
		pl: 10,
		fi: 9,
		ee: 5,
		se: 14,
		us: 8,
		kz: 7,
		mn: 12,
		no: 11,
		bg: 13,
	},
	2018: {
		ua: 4,
		lt: 3,
		lv: 2,
		ru: 1,
		by: 7,
		pl: 5,
		fi: 8,
		ee: 11,
		se: 12,
		us: 9,
		kz: 6,
		mn: 10,
		no: 13,
	},
	2019: {
		ua: 3,
		lt: 1,
		lv: 4,
		ru: 6,
		by: 2,
		pl: 7,
		fi: 5,
		ee: 9,
		se: 12,
		us: 10,
		mn: 13,
		no: 11,
		bg: 8,
		cz: 14,
		ro: 15,
	},
	2020: {
		ua: 3,
		lt: 4,
		lv: 5,
		ru: 2,
		by: 7,
		pl: 8,
		fi: 1,
		ee: 10,
		se: 9,
		kz: 12,
		mn: 11,
		no: 6,
		ro: 13,
	},
	2022: {
		ua: 3,
		lt: 1,
		lv: 8,
		ru: 5,
		by: 2,
		pl: 4,
		fi: 10,
		ee: 7,
		se: 11,
		mn: 6,
		no: 9,
		ro: 12,
	},
	2023: {
		ua: 1,
		lt: 2,
		lv: 3,
		pl: 5,
		fi: 7,
		ee: 8,
		se: 9,
		us: 10,
		mn: 6,
		no: 4,
		ro: 11,
	},
	2024: {
		lt: 1,
		us: 2,
		ua: 3,
		ee: 4,
		fi: 5,
		lv: 6,
		kz: 7,
		mn: 8,
		se: 9,
		no: 10,
	},
};

const RankHistory = () => {
	return (
		<div>
			<Title>{t("titles.rank_history")}</Title>
			<div className="overflow-x-auto" style={{ minHeight: 500 }}>
				<table className="table-fixed w-full result mx-auto z-10 text-sm">
					<thead className="bg-white sticky top-0">
						<tr>
							<th className="w-16">RANK</th>
							<th className="w-16 sticky left-0 bg-white z-10"></th>
							<th className="w-48 text-left">NATIONS</th>
							{YEARS.map((y) => (
								<th key={y} className="w-12">
									{y}
								</th>
							))}
							<th className="w-32">TOTAL</th>
						</tr>
					</thead>
					<tbody>
						{NATIONS.map((code, index) => {
							return (
								<tr key={code}>
									<td className="text-center font-bold">
										<div className="relative">{index + 1}</div>
									</td>
									<td className="text-center sticky left-0 bg-glass z-10">
										<ReactCountryFlag
											svg={true}
											countryCode={code}
											style={{ fontSize: 30, height: "auto" }}
											className="border-none"
										/>
									</td>
									<td className="">{NAMES[code]}</td>
									{YEARS.map((y) => {
										const exist = DATA[y][code];
										let rank = exist || Object.keys(DATA[y]).length + 1;

										return (
											<td
												key={y}
												className={`text-center ${
													exist ? "" : "bg-gray-300 opacity-40"
												}`}
											>
												<div className="relative">
													{rank}{" "}
													{rank === 1 && <Pulse color="bg-yellow-100"></Pulse>}
												</div>
											</td>
										);
									})}
									<td className="text-center font-bold">
										{YEARS.reduce(
											(p, y) =>
												p + (DATA[y][code] || Object.keys(DATA[y]).length + 1),
											0
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="p-5 border flex items-start border-red-100 shadow-md mt-10 text-sm">
				<div className="w-12 h-8 bg-gray-300 opacity-40 flex justify-center items-center mr-2 flex-shrink-0">
					??
				</div>
				<div className="self-center">{t("label.absent")}</div>
			</div>
		</div>
	);
};

export default RankHistory;

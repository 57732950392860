import { useTranslation } from "react-i18next";
import Countdown from "../components/Countdown";
import { Link } from "react-router-dom";
import { RiCalendarLine } from "react-icons/ri";

const Starts = ({ showLink = true }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-wrap items-center gap-5 p-5 bg-yellow-100 text-white justify-end relative z-10">
			<div className="flex-1 text-2xl text-center leading-6">
				{t("label.until")}:
			</div>
			<div className="flex-1">
				<Countdown
					day={t("counter.day")}
					hour={t("counter.hour")}
					minute={t("counter.minute")}
					second={t("counter.second")}
				></Countdown>
			</div>
			{showLink && (
				<Link to="/agenda" className="link">
					<RiCalendarLine className="text-2xl mr-2"></RiCalendarLine>
					{t("label.agenda")}
				</Link>
			)}
		</div>
	);
};

export default Starts;

import { VERSION, PHONE, EMAIL, WEB } from "../defines";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const { t } = useTranslation();

	const YEAR = new Date().getFullYear();

	return (
		<div className="bg-dark-100 py-8 relative z-10">
			<div className="safe text-white flex flex-col sm:flex-row justify-end gap-5 items-end sm:items-center">
				<div className="text-xs flex flex-col items-end gap-2">
					<div>
						{t("contact.phone")}:{" "}
						<a
							className="underline"
							target="_blank"
							rel="noreferrer"
							href={`tel:${PHONE}`}
						>
							{PHONE}
						</a>
					</div>
					<div>
						{t("contact.web")}:{" "}
						<a
							className="underline"
							target="_blank"
							rel="noreferrer"
							href={`${WEB}`}
						>
							{WEB}
						</a>
					</div>
					<div className="mb-2">
						{t("contact.email")}:{" "}
						<a
							className="underline"
							target="_blank"
							rel="noreferrer"
							href={`mailto:${EMAIL}`}
						>
							{EMAIL}
						</a>
					</div>
					<div className="text-right">{t("contact.address")}</div>
					<div className="text-right">
						{t("contact.copyright")} v{VERSION} ©{YEAR}
					</div>
				</div>
				<img src={"img/msff.png"} className="h-32 mr-0" alt="" />
			</div>
		</div>
	);
};

export default Footer;

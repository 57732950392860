import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Trans, useTranslation } from "react-i18next";
import Title from "../components/Title";
import Watermark from "../components/Watermark";

import Swiper from "../components/Swiper";
import { RiErrorWarningFill, RiMapPin2Line } from "react-icons/ri";
import Swal from "sweetalert2";

const CAMPS = [
	{
		active: true,
		name: {
			mn: "СОНГОЛТ #1 ASA LAND ЖУУЛЧНЫ БААЗ",
			en: "OPTION #1 ASA LAND TOURIST CAMP",
		},
		location: "kharkhorum",
		intro: {
			mn: "WIFC 2024-ийн албан ёсны түнш. Нээлтийн ёслол, хаалтын үдэшлэг болох албан ёсны газар. Өртөг нь стандарт хоёр өрөөнд нэг хүнд ногдох 80.00 евро (өглөөний цай), эсвэл нэг хүнд ногдох өдрийн 90.00 евро (өглөөний цай, оройн хоол), буудлаас тэмцээний талбай руу автобусаар өдөр бүр шилжүүлэх, буцах.",
			en: "Official partner of WIFC 2024. The official venue for the Opening Ceremony and Closing Party.  The cost is 80.00 euro per person in standart double room per person per day (breakfast), or 90.00 euro per person per day (breakfast, dinner), daily transfer by bus from the hotel to the competition area and back. ",
		},
		images: [
			"/img/about/24.png",
			"/img/about/25.png",
			"/img/about/26.png",
			"/img/about/27.png",
			"/img/about/28.png",
		],
	},
	{
		active: true,
		name: {
			mn: "СОНГОЛТ #2 ИХ ХОРУМ ЗОЧИД БУУДАЛ",
			en: "OPTION #2 IKH KHORUM HOTEL",
		},
		location: "kharkhorum",
		intro: {
			mn: "Өртөг нь стандарт хоёр өрөөнд нэг хүнд ногдох 55.00 евро (өглөөний цай), эсвэл нэг хүнд ногдох өдрийн 60.00 евро (өглөөний цай, оройн хоол), буудлаас тэмцээний талбай руу автобусаар өдөр бүр хүргэж өгөх болон буцаан авах.",
			en: "The cost is 55.00 euro per person in standart double room per person per day (breakfast), or 60.00 euro per person per day (breakfast, dinner), daily transfer by bus from the hotel to the competition area and back.",
		},
		images: [
			"/img/about/7.png",
			"/img/about/8.png",
			"/img/about/9.png",
			"/img/about/10.png",
			"/img/about/11.png",
			"/img/about/12.png",
		],
	},
	{
		name: {
			mn: "СОНГОЛТ #3 ӨГИЙ ХИШИГ ЖУУЛЧНЫ БААЗ",
			en: "OPTION #3 UGII KHISHIG TOURIST CAMP",
		},
		location: "ugii",
		intro: {
			mn: "WIFC 2024-ийн албан ёсны түнш.  Өртөг нь стандарт хоёр өрөөнд нэг хүнд ногдох 55.00 евро (өглөөний цай), эсвэл нэг хүнд ногдох өдрийн 70.00 евро (өглөөний цай, оройн хоол), буудлаас тэмцээний талбай руу автобусаар өдөр бүр хүргэж өгөх болон буцаан авах.",
			en: "Official partner of WIFC 2024. The cost is 55.00 euro per person in standart double room per person per day (breakfast), or 70.00 euro per person per day (breakfast, dinner), daily transfer by bus from the hotel to the competition area and back. ",
		},
		images: [
			"/img/about/13.png",
			"/img/about/14.png",
			"/img/about/15.png",
			"/img/about/16.png",
			"/img/about/17.png",
		],
	},
	{
		name: {
			mn: "СОНГОЛТ #4 ТОРГОНЫ ЗАМЫН НУУЦ РЕСОРТ",
			en: "OPTION #4 SECRET  OF THE SILK ROAD CAMP",
		},
		location: "kharkhorum",
		intro: {
			mn: "WIFC 2024-ийн албан ёсны түнш.  Өртөг нь стандарт хоёр өрөөнд нэг хүнд ногдох 55.00 евро (өглөөний цай), эсвэл нэг хүнд ногдох өдрийн 70.00 евро (өглөөний цай, оройн хоол), буудлаас тэмцээний талбай руу автобусаар өдөр бүр хүргэж өгөх болон буцаан авах.",
			en: "Official partner of WIFC 2024. The cost is 55.00 euro per person in standart double room per person per day (breakfast), or 70.00 euro per person per day (breakfast, dinner), daily transfer by bus from the hotel to the competition area and back. ",
		},
		images: [
			"/img/about/18.png",
			"/img/about/19.png",
			"/img/about/20.png",
			"/img/about/21.png",
			"/img/about/22.png",
			"/img/about/23.png",
		],
	},
];

const FISH = [
	{
		src: "/img/fish/10.jpeg",
		label: {
			mn: "тул",
			en: "taimen",
		},
		limit: true,
	},
	{
		src: "/img/fish/13.jpeg",
		label: {
			mn: "зэвэг",
			en: "lenok",
		},
	},
	{
		src: "/img/fish/9.jpeg",
		label: {
			mn: "ердийн цурхай",
			en: "northern pike",
		},
	},
	{
		src: "/img/fish/3.jpeg",
		label: {
			mn: "гутаар",
			en: "burbot",
		},
	},
	{
		src: "/img/fish/8.jpeg",
		label: {
			mn: "ердийн цулбуурт",
			en: "east asian catfish",
		},
	},
	{
		src: "/img/fish/1.jpeg",
		label: {
			mn: "алгана",
			en: "perch",
		},
	},
	{
		src: "/img/fish/2.jpeg",
		label: {
			mn: "булуу цагаан",
			en: "asian common carp",
		},
	},
	{
		src: "/img/fish/4.jpeg",
		label: {
			mn: "шивэр хадран",
			en: "arctic grayling",
		},
	},
	{
		src: "/img/fish/5.jpeg",
		label: {
			mn: "мөнгөлөг хэлтэг",
			en: "prussion carp",
		},
	},
	{
		src: "/img/fish/6.jpeg",
		label: {
			mn: "сахалт эрээлж",
			en: "siberian stone loach",
		},
	},
	{
		src: "/img/fish/7.jpeg",
		label: {
			mn: "шивэр сугас",
			en: "siberian dace",
		},
	},
	{
		src: "/img/fish/11.jpeg",
		label: {
			mn: "улаан нүдэн",
			en: "roach",
		},
	},
	{
		src: "/img/fish/12.jpeg",
		label: {
			mn: "бух сугас",
			en: "ide",
		},
	},
];

const About = () => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;

	const openModal = (image) => {
		return Swal.fire({
			imageUrl: image,
			imageAlt: image,
			heightAuto: true,
			width: 1000,
			showConfirmButton: false,
			showCloseButton: true,
			padding: 5,
		});
	};

	return (
		<div className="p-5 bg-white relative text-sm">
			<Watermark></Watermark>
			<div className="relative z-10 mt-8">
				<div className="grid md:grid-cols-2 grid-cols-1 gap-8 items-start">
					<div className="uppercase">
						<Title justify="left">{t(`titles.kharkhorum`)}</Title>
						<Trans t={t}>label.about_kharkhorum</Trans>
					</div>
					<div>
						<iframe
							title="KharKhorum"
							src="https://www.google.com/maps/embed?pb=!1m32!1m12!1m3!1d1378871.053166671!2d103.4903306697797!3d47.54623595982491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m17!3e0!4m3!3m2!1d47.903966499999996!2d106.9288175!4m5!1s0x5d9dccabbe688e19%3A0x21071fd56fb8f853!2sUgii%20Lake!3m2!1d47.7740484!2d102.78299919999999!4m5!1s0x5d9cedfde6f2b085%3A0xc8557feca7e1fe0f!2sKharkhorin!3m2!1d47.1900322!2d102.8340449!5e0!3m2!1sen!2smn!4v1694692556296!5m2!1sen!2smn"
							width="100%"
							height="300"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</div>
				<div className="mt-16">
					<Swiper
						height={300}
						items={[
							"/img/about/hh1.jpeg",
							"/img/about/hh3.jpeg",
							"/img/about/hh2.jpeg",
							"/img/about/hh5.jpeg",
							"/img/about/hh6.jpeg",
							"/img/about/hh7.jpeg",
							"/img/about/hh4.jpeg",
							"/img/about/hh9.jpeg",
							"/img/about/hh8.jpeg",
						]}
					></Swiper>
				</div>
				<div className="p-8"></div>
				<div className="grid md:grid-cols-2 grid-cols-1 gap-8 items-start">
					<div className="uppercase">
						<Title justify="left">{t(`titles.ugii`)}</Title>
						<Trans t={t} className="uppercase">
							label.about_ugii
						</Trans>
					</div>
					<iframe
						title="Ugii lake"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54190.569565639475!2d102.72190998183389!3d47.769905728357244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9dccabbe688e19%3A0x21071fd56fb8f853!2sUgii%20Lake!5e1!3m2!1sen!2smn!4v1693826107811!5m2!1sen!2smn"
						width="100%"
						height="300"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
				<div className="mt-8">
					<div className="text-xl text-blue-100">{t("label.species")}</div>
					<div className="grid md:grid-cols-4 grid-cols-2 gap-5 my-5">
						{FISH.map(({ src, label, limit }, index) => {
							const name = label[language];
							return (
								<div
									key={index}
									className="h-32 p-2 shadow-lg justify-center flex flex-col bg-white"
								>
									<div className="text-xs uppercase mb-2 border-b pb-2 relative">
										{name}
										{limit && (
											<span className="flex h-4 w-4 ml-4 absolute top-0 -right-2">
												<span
													className={`animate-ping absolute inline-flex h-4 w-4 -mt-1 -ml-1 rounded-full bg-red-100 opacity-75`}
												></span>
												<span
													className={`relative inline-flex rounded-full h-2 w-2 bg-red-100`}
												></span>
											</span>
										)}
									</div>
									<img
										src={src}
										alt={label}
										className="h-16 object-contain"
									></img>
								</div>
							);
						})}
					</div>
					<div className="p-5 border flex items-start border-red-100 shadow-md">
						<RiErrorWarningFill className="text-2xl mr-2 text-red-100 flex-shrink-0"></RiErrorWarningFill>{" "}
						<div className="self-center">{t("label.limitation")}</div>
					</div>

					<div className="py-24 text-center text-xl text-blue-100">
						<div className="w-full md:w-2/3 mx-auto">{t("label.catches")}</div>
					</div>
				</div>
				<div className="p-8"></div>
				<Title justify="left">{t(`titles.camps`)}</Title>
				<div>
					{CAMPS.map(({ images, name, intro, location, active }, index) => {
						const n = name[language];
						const i = intro[language];

						return (
							<div
								key={index}
								className={`flex flex-col gap-5 mb-8 pb-8 border-b ${
									active ? "" : "opacity-50"
								}`}
							>
								<div>
									<div className="text-xl text-blue-100">
										{n} {active ? "" : `(UNAVAILABLE)`}
									</div>
									<div className="mb-5 mt-2 uppercase flex items-center">
										<RiMapPin2Line className="text-lg mr-1"></RiMapPin2Line>{" "}
										{t(`label.location`)}: {t(`label.${location}`)}
									</div>
									<div className="text-sm">{i}</div>
								</div>
								<div className="flex gap-2 flex-wrap">
									{images.map((src, index) => (
										<img
											key={index}
											src={src}
											alt={n}
											className="md:h-48 h-auto w-full md:w-auto cursor-pointer hover:opacity-60 object-contain"
											onClick={() => {
												openModal(src, n);
											}}
										/>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default About;

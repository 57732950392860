const PARTNERS = {
	"boaj.png": 50,
	"bts.png": 50,
	"mto.png": 40,
	"cips.jpeg": 100,
	"fipsed.jpg": 100,
	"msff.png": 100,
	"ice.png": 100,
	// "mnoc.jpeg": 100,
};

const Organizers = () => {
	return (
		<div
			className="flex flex-wrap items-center justify-center gap-12 px-5 mx-auto mt-12"
			style={{ maxWidth: 800 }}
		>
			{Object.entries(PARTNERS).map(([src, height], index) => (
				<>
					{index === 3 && <div className="w-full h-1" />}
					<img
						key={src}
						style={{ height }}
						src={`img/partners/${src}`}
						alt={src}
					/>
				</>
			))}
		</div>
	);
};

export default Organizers;

import { useTranslation } from "react-i18next";
import {
	RiFacebookBoxFill,
	RiMailLine,
	RiPhoneLine,
	RiStarFill,
	RiUserStarFill,
} from "react-icons/ri";

import ReactCountryFlag from "react-country-flag";

const Guide = ({ name, phone, email, facebook, club, img, flag, team }) => {
	const { t } = useTranslation();

	return (
		<div key={name} className="flex flex-col md:flex-row gap-5">
			<div className="w-32 relative flex-shrink-0">
				{flag && (
					<div className="flex items-center mb-2">
						<ReactCountryFlag
							svg={true}
							countryCode={flag}
							style={{ fontSize: 30, height: "auto" }}
							className="border shadow-md mr-2"
						/>
						<div className="text-xs">{team}</div>
					</div>
				)}
				<img
					className="h-32 w-full object-cover"
					src={`img/guides/${img}`}
					alt={name}
				/>
			</div>
			<div className="text-xs flex flex-col gap-2">
				<div className="flex">
					<RiStarFill className="mr-2 flex-shrink-0 text-lg text-yellow-100"></RiStarFill>
					{club}
				</div>
				<div className="flex">
					<RiUserStarFill className="mr-2 flex-shrink-0 text-lg text-blue-100"></RiUserStarFill>
					{name}
				</div>
				{phone && (
					<div className="flex">
						<RiPhoneLine className="mr-2 flex-shrink-0 text-lg text-blue-100"></RiPhoneLine>
						<a
							target="_blank"
							rel="noreferrer"
							href={`tel:${phone}`}
							className="underline"
						>
							{t("label.call")}: {phone}
						</a>
					</div>
				)}
				{email && (
					<div className="flex">
						<RiMailLine className="mr-2 flex-shrink-0 text-lg text-blue-100"></RiMailLine>
						<a
							target="_blank"
							rel="noreferrer"
							href={`mailto:${email}`}
							className="underline"
						>
							{t("label.send-email")}: {email}
						</a>
					</div>
				)}
				{facebook && (
					<div className="flex overflow-hidden">
						<RiFacebookBoxFill className="mr-2 flex-shrink-0 text-lg text-blue-100"></RiFacebookBoxFill>
						<a
							target="_blank"
							rel="noreferrer"
							href={facebook}
							className="underline truncate w-48"
						>
							{t("label.fb-connect")}: {facebook}
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

export default Guide;

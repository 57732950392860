const PARTNERS = {
	"don.svg": 100,
	"tiffin.png": 100,
	"flames.svg": 100,
	"asa.png": 100,
	"livetv.png": 100,
	"mnb.jpg": 100,
	"khanbogd.png": 100,
	"mobicom.png": 100,
	"ecoflow.png": 100,
	"cnc.svg": 100,
};

const Partners = () => {
	return (
		<div
			className="flex flex-wrap justify-center items-center gap-12 px-5 mx-auto"
			// style={{ maxWidth: 800 }}
		>
			{Object.entries(PARTNERS).map(([key, height]) => (
				<img
					key={key}
					className="w-auto object-contain"
					style={{ height }}
					src={`img/partners/${key}`}
					alt={key}
				/>
			))}
		</div>
	);
};

export default Partners;

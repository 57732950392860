import packageJson from "../package.json";

export const DEBUG = process.env.NODE_ENV === "development";
export const API_ROOT = DEBUG ? "http://localhost:3000" : "https://api.msff.mn";
export const VERSION = packageJson.version;
export const LANGUAGES = ["en", "mn"];
export const DOMAIN = "https://wifc.msff.mn";
export const DEMO = new Date() < new Date("2023-09-30");

export const PHONE = "+976 99107653";
export const EMAIL = "info@msff.mn";
export const WEB = "www.msff.mn";

const Watermark = () => {
	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center opacity-5 z-0">
			<img
				src="/img/20th_wifc_logo_transparent.png"
				alt="20th World Ice Fishing Championship"
			/>
		</div>
	);
};

export default Watermark;

import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { LANGUAGES } from "../defines";
import { useEffect } from "react";

const FLAGS = { mn: "mn", en: "gb" };

const LanguageSwitch = () => {
	const { i18n } = useTranslation();
	const { language, changeLanguage } = i18n;

	useEffect(() => {
		if (!LANGUAGES.includes(language)) {
			changeLanguage(LANGUAGES[0]);
		}
	}, [language, changeLanguage]);

	return (
		<div className="flex items-center select-none">
			<ReactCountryFlag svg={true} countryCode={FLAGS[language]} />
			<select
				name="language-switcher"
				className="ml-2 uppercase bg-transparent cursor-pointer"
				onChange={(e) => {
					i18n.changeLanguage(e.target.value);
				}}
				value={language}
			>
				{LANGUAGES.map((locale, index) => (
					<option key={index} value={locale}>
						{locale}
					</option>
				))}
			</select>
		</div>
	);
};

export default LanguageSwitch;

import { useTranslation } from "react-i18next";

const Marquee = ({ path = "marquee", borderColor = "" }) => {
	const { t } = useTranslation();

	return (
		<div
			className={`w-full bg-white p-2 border-b ${borderColor} flex items-center uppercase text-gray-500`}
		>
			<div className="marquee">
				<p>{t(path)}</p>
			</div>
		</div>
	);
};

export default Marquee;

import { Link } from "react-router-dom";
import Watermark from "../components/Watermark";
import { useTranslation } from "react-i18next";

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<div className="w-full h-full relative bg-white flex items-center justify-center">
			<Watermark></Watermark>
			<div className="z-10">
				<div className="text-2xl">{t("label.notfound")}</div>
				<Link className="link" to="/">
					{t("menu.home")}
				</Link>
			</div>
		</div>
	);
};

export default NotFound;

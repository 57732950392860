import Title from "../components/Title";
import data from "../data/result.json";
import ReactCountryFlag from "react-country-flag";
import { useEffect, useState } from "react";
import Starts from "../components/Until";
import RankHistory from "../components/RankHistory";
import Watermark from "../components/Watermark";
import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import PieChart from "../components/PieChart";

const CODES = {
	all: "all",
	Estonia: "ee",
	Finland: "fi",
	Kazakhstan: "kz",
	Latvia: "lv",
	Lithuania: "lt",
	Mongolia: "mn",
	Norway: "no",
	Sweden: "se",
	Ukraine: "ua",
	USA: "us",
};

const NATIONS = Object.keys(CODES);
const SECTORS = ["A", "B", "C", "D", "E"];

const teamSort = (a, b) => {
	return a.penalty - b.penalty || b.weight - a.weight;
};

const indvidualFinalSort = (a, b) => {
	return (
		b.played - a.played ||
		a.penalty - b.penalty ||
		b.weight - a.weight ||
		b.peg - a.peg
	);
};

const indvidualSort = (a, b) => {
	return a.penalty - b.penalty || b.weight - a.weight || b.peg - a.peg;
};

let indFinal = {};
let team1 = {};
let team2 = {};
let teamFinal = {};

const pie1 = Object.entries(data[0].sectors).map(([value, items]) => ({
	sector: value,
	weight: items.reduce((prev, cur) => prev + cur.weight, 0),
}));

const pie2 = Object.entries(data[1].sectors).map(([value, items]) => ({
	sector: value,
	weight: items.reduce((prev, cur) => prev + cur.weight, 0),
}));

const ind1 = Object.entries(data[0].sectors)
	.reduce((prev, [sector, weights]) => {
		prev.push(
			...weights.map((w) => {
				indFinal[w.img] = {
					...w,
					penalty: w.penalty,
					penalities: { 1: w.penalty, 2: "-" },
					weight: w.weight,
					weights: { 1: w.weight, 2: "-" },
					pegs: { 1: sector + w.peg, 2: "-" },
					played: 1,
				};

				if (team1[w.nation]) {
					team1[w.nation].sectors[sector] = w.peg;
					team1[w.nation].penalities[sector] = w.penalty;
					team1[w.nation].weights[sector] = w.weight;
					team1[w.nation].penalty += w.penalty;
					team1[w.nation].weight += w.weight;
				} else {
					team1[w.nation] = {
						sectors: { [sector]: w.peg },
						penalities: { [sector]: w.penalty },
						weights: { [sector]: w.weight },
						penalty: w.penalty,
						weight: w.weight,
					};
				}

				return { ...w, sector };
			})
		);
		return prev;
	}, [])
	.sort(indvidualSort)
	.map((i, index) => ({ ...i, rank: index + 1 }));

team1 = Object.entries(team1)
	.map(([value, t]) => ({ ...t, nation: value }))
	.sort(teamSort)
	.map((i, index) => {
		teamFinal[i.nation] = {
			nation: i.nation,
			weight: i.weight,
			penalty: i.penalty,
			leg1: i,
		};

		return { ...i, rank: index + 1 };
	});

const ind2 = Object.entries(data[1].sectors)
	.reduce((prev, [sector, weights]) => {
		prev.push(
			...weights.map((w) => {
				if (!indFinal[w.img])
					indFinal[w.img] = {
						...w,
						penalty: w.penalty,
						penalities: { 1: "-", 2: w.penalty },
						weight: w.weight,
						weights: { 1: "-", 2: w.weight },
						pegs: { 1: "-", 2: sector + w.peg },
						played: 1,
					};
				else {
					indFinal[w.img].penalty += w.penalty;
					indFinal[w.img].penalities[2] = w.penalty;
					indFinal[w.img].weight += w.weight;
					indFinal[w.img].weights[2] = w.weight;
					indFinal[w.img].pegs[2] = sector + w.peg;
					indFinal[w.img].played = 2;
				}

				if (team2[w.nation]) {
					team2[w.nation].sectors[sector] = w.peg;
					team2[w.nation].penalities[sector] = w.penalty;
					team2[w.nation].weights[sector] = w.weight;
					team2[w.nation].penalty += w.penalty;
					team2[w.nation].weight += w.weight;
				} else {
					team2[w.nation] = {
						sectors: { [sector]: w.peg },
						penalities: { [sector]: w.penalty },
						weights: { [sector]: w.weight },
						penalty: w.penalty,
						weight: w.weight,
					};
				}

				return { ...w, sector };
			})
		);
		return prev;
	}, [])
	.sort(indvidualSort)
	.map((i, index) => ({ ...i, rank: index + 1 }));

team2 = Object.entries(team2)
	.map(([value, t]) => ({ ...t, nation: value }))
	.sort(teamSort)
	.map((i, index) => {
		teamFinal[i.nation].weight += i.weight;
		teamFinal[i.nation].penalty += i.penalty;
		teamFinal[i.nation].leg2 = i;

		return { ...i, rank: index + 1 };
	});

indFinal = Object.values(indFinal)
	.sort(indvidualFinalSort)
	.map((i, index) => ({ ...i, rank: index + 1 }));

teamFinal = Object.values(teamFinal)
	.sort(teamSort)
	.map((i, index) => ({ ...i, rank: index + 1 }));

const WEIGHTS = {
	1: ind1,
	2: ind2,
	final: indFinal,
};

const comma = (x) => {
	if (x === 0) return "0";
	if (x === "-") return "-";
	if (!x) return "";

	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Teams = ({ teams, final }) => {
	return (
		<div className="mb-10">
			<div className="overflow-y-scroll">
				<table className="table-fixed result w-full mx-auto relative z-10 text-xs">
					<thead className="bg-white sticky top-0">
						<tr>
							<th className="w-16" rowSpan={2}>
								Ranking
							</th>
							<th className="text-left w-32" rowSpan={2}>
								Nation
							</th>
							<th className="w-72" colSpan={final ? 7 : 6}>
								Penalities
							</th>
							<th className="w-96" colSpan={final ? 7 : 6}>
								Weight
							</th>
							<th className="w-60" colSpan={5}>
								Pegs
							</th>
						</tr>
						<tr>
							<th>Final</th>
							{final && <th>Legs</th>}
							{SECTORS.map((s) => (
								<th key={s}>{s}</th>
							))}
							<th>Total</th>
							{final && <th>Legs</th>}
							{SECTORS.map((s) => (
								<th key={s}>{s}</th>
							))}

							{SECTORS.map((s) => (
								<th key={s}>{s}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{teams.map((w, index) => {
							return (
								<>
									<tr key={index}>
										<td className="text-center" rowSpan={final ? 2 : 1}>
											{w.rank}
										</td>
										<td rowSpan={final ? 2 : 1}>
											<div className="flex gap-2 items-center w-32">
												<ReactCountryFlag
													countryCode={CODES[w.nation]}
													style={{ fontSize: 30, height: "auto" }}
													svg={true}
												></ReactCountryFlag>{" "}
												{w.nation}
											</div>
										</td>
										<td
											className="text-center font-bold"
											rowSpan={final ? 2 : 1}
										>
											{w.penalty}
										</td>
										{final && (
											<td className="text-center">
												{objectPath.get(w, `leg1.penalty`)}
											</td>
										)}
										{final &&
											SECTORS.map((s) => (
												<td key={s} className="text-center">
													{objectPath.get(w, `leg1.penalities.${s}`)}
												</td>
											))}

										{final && (
											<>
												<td
													className="text-center font-bold"
													rowSpan={final ? 2 : 1}
												>
													{comma(w.weight)}
												</td>
												<td className="text-center">
													{comma(objectPath.get(w, `leg1.weight`))}
												</td>
												{SECTORS.map((s) => (
													<td key={s} className="text-center">
														{comma(objectPath.get(w, `leg1.weights.${s}`))}
													</td>
												))}
												{SECTORS.map((s) => (
													<td key={s} className="text-center">
														{comma(objectPath.get(w, `leg1.sectors.${s}`))}
													</td>
												))}
											</>
										)}
										{!final &&
											SECTORS.map((s) => (
												<td key={s} className="text-center">
													{objectPath.get(w, `penalities.${s}`)}
												</td>
											))}

										{!final && (
											<td
												className="text-center font-bold"
												rowSpan={final ? 2 : 1}
											>
												{comma(w.weight)}
											</td>
										)}
										{!final &&
											SECTORS.map((s) => (
												<td key={s} className="text-center">
													{comma(objectPath.get(w, `weights.${s}`))}
												</td>
											))}
										{!final &&
											SECTORS.map((s) => (
												<td key={s} className="text-center">
													{objectPath.get(w, `sectors.${s}`)}
												</td>
											))}
									</tr>
									{final && (
										<tr key={index + "d"}>
											<td className="text-center">
												{objectPath.get(w, `leg2.penalty`)}
											</td>
											{SECTORS.map((s) => (
												<td key={s} className="text-center">
													{objectPath.get(w, `leg2.penalities.${s}`)}
												</td>
											))}
											<td className="text-center">
												{comma(objectPath.get(w, `leg2.weight`))}
											</td>
											{SECTORS.map((s) => (
												<td key={s} className="text-center">
													{comma(objectPath.get(w, `leg2.weights.${s}`))}
												</td>
											))}
											{SECTORS.map((s) => (
												<td key={s} className="text-center">
													{comma(objectPath.get(w, `leg2.sectors.${s}`))}
												</td>
											))}
										</tr>
									)}
								</>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const Weights = ({ weights, sector, final }) => {
	return (
		<div>
			<div className="overflow-y-scroll">
				<table className="table-fixed result w-full mx-auto relative z-10 text-xs">
					<thead className="bg-white sticky top-0">
						<tr>
							<th className="w-16" rowSpan={final ? 2 : 1}>
								Ranking
							</th>
							<th className="text-left w-48" rowSpan={final ? 2 : 1}>
								Angler
							</th>
							<th className="text-left w-32" rowSpan={final ? 2 : 1}>
								Nation
							</th>
							<th className="w-48" colSpan={final ? 3 : 1}>
								Penalities
							</th>
							<th className="w-48" colSpan={final ? 3 : 1}>
								Weight
							</th>
							<th className="w-32" colSpan={final ? 2 : 1}>
								Peg
							</th>
						</tr>
						{final && (
							<tr>
								<th className="w-16">Final</th>
								<th className="w-16">Leg 1</th>
								<th className="w-16">Leg 2</th>
								<th>Total</th>
								<th>Leg 1</th>
								<th>Leg 2</th>
								<th>Leg 1</th>
								<th>Leg 2</th>
							</tr>
						)}
					</thead>
					<tbody>
						{weights.map((w, index) => (
							<tr key={index}>
								<td className="text-center">{w.rank}</td>
								<td>
									<div className="flex gap-2 items-center w-48">
										<img
											src={w.img}
											className="w-16 h-16 object-cover flex-shrink-0"
										/>
										{w.angler}
									</div>
								</td>
								<td>
									<div className="flex gap-2 items-center w-32">
										<ReactCountryFlag
											countryCode={CODES[w.nation]}
											style={{ fontSize: 30, height: "auto" }}
											svg={true}
										></ReactCountryFlag>{" "}
										{w.nation}
									</div>
								</td>
								<td className="text-center">{w.penalty}</td>
								{final && (
									<>
										<td className="text-center">
											{objectPath.get(w, "penalities.1")}
										</td>
										<td className="text-center">
											{objectPath.get(w, "penalities.2")}
										</td>
									</>
								)}
								<td className="text-center"> {comma(w.weight)}</td>
								{final && (
									<>
										<td className="text-center">
											{comma(objectPath.get(w, "weights.1"))}
										</td>
										<td className="text-center">
											{comma(objectPath.get(w, "weights.2"))}
										</td>
										<td className="text-center">
											{objectPath.get(w, "pegs.1")}
										</td>
										<td className="text-center">
											{objectPath.get(w, "pegs.2")}
										</td>
									</>
								)}
								{!final && (
									<td className="text-center">{`${w.sector || sector}${
										w.peg
									}`}</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const Results = () => {
	const { t } = useTranslation();
	const [leg, setLeg] = useState("final");
	const [nations, setNations] = useState("all");
	const [sectors, setSectors] = useState("all");

	const [weights, setWeights] = useState(indFinal);
	const [teams, setTeams] = useState(teamFinal);

	useEffect(() => {
		if (leg === "final") {
			setWeights(indFinal);
			setTeams(teamFinal);
		}
		if (leg === "1") {
			setWeights(ind1);
			setTeams(team1);
		}
		if (leg === "2") {
			setWeights(ind2);
			setTeams(team2);
		}

		setSectors("all");
		setNations("all");
	}, [leg]);

	useEffect(() => {
		setSectors("all");
		if (nations === "all") {
			setWeights(WEIGHTS[leg]);
		} else {
			setWeights(WEIGHTS[leg].filter((i) => i.nation === nations));
		}
	}, [nations]);

	useEffect(() => {
		setNations("all");
		if (sectors === "all") {
			setWeights(WEIGHTS[leg]);
		} else {
			setWeights(WEIGHTS[leg].filter((i) => i.sector === sectors));
		}
	}, [sectors]);

	return (
		<>
			<Starts></Starts>
			<div className="bg-white p-5 relative" style={{ minHeight: 500 }}>
				<Watermark></Watermark>
				<div>
					<Title justify="left">TEAM RANK</Title>
					<div className="flex gap-2 mb-2">
						<button
							className={`button ${leg === "final" ? "active" : ""}`}
							onClick={() => {
								setLeg("final");
							}}
						>
							Final
						</button>
						<button
							className={`button ${leg === "1" ? "active" : ""}`}
							onClick={() => {
								setLeg("1");
							}}
						>
							Leg 1
						</button>
						<button
							className={`button ${leg === "2" ? "active" : ""}`}
							onClick={() => {
								setLeg("2");
							}}
						>
							Leg 2
						</button>
					</div>
					<Teams key={leg} teams={teams} final={leg === "final"}></Teams>

					<Title justify="left">INDVIDUAL RANK</Title>
					<div className="overflow-x-auto mb-10">
						<div className="flex gap-2 mb-2">
							<button
								className={`button ${leg === "final" ? "active" : ""}`}
								onClick={() => {
									setLeg("final");
								}}
							>
								Final
							</button>
							<button
								className={`button ${leg === "1" ? "active" : ""}`}
								onClick={() => {
									setLeg("1");
								}}
							>
								Leg 1
							</button>
							<button
								className={`button ${leg === "2" ? "active" : ""}`}
								onClick={() => {
									setLeg("2");
								}}
							>
								Leg 2
							</button>
						</div>
						<div className="flex flex-col gap-2 mb-10">
							{leg !== "final" && (
								<div className="flex gap-2">
									{["all", ...SECTORS].map((sector) => (
										<button
											key={sector}
											className={`button ${sectors === sector ? "active" : ""}`}
											onClick={() => {
												setSectors(sector);
											}}
										>
											{sector !== "all" && "SECTOR"} {sector}
										</button>
									))}
								</div>
							)}
							<div className="flex gap-2 ">
								{NATIONS.map((nation) => (
									<button
										key={nation}
										className={`button ${nations === nation ? "active" : ""}`}
										onClick={() => {
											setNations(nation);
										}}
									>
										{nation}
									</button>
								))}
							</div>
						</div>
						<Weights
							key={nations + sectors + leg}
							weights={weights}
							final={leg === "final"}
						></Weights>
					</div>
				</div>
				<div className="grid md:grid-cols-2 grid-cols-1 gap-5 mb-24">
					<div className="w-full">
						<Title>{t("label.round-one")}</Title>
						<PieChart
							data={pie1}
							dataKey="weight"
							nameKey="sector"
							renderLabel={({ sector, weight }) =>
								`Sector ${sector}: ${comma(weight)}g`
							}
						></PieChart>
					</div>
					<div className="w-full">
						<Title>{t("label.round-two")}</Title>
						<PieChart
							data={pie2}
							dataKey="weight"
							nameKey="sector"
							renderLabel={({ sector, weight }) =>
								`Sector ${sector}: ${comma(weight)}g`
							}
						></PieChart>
					</div>
				</div>
				<div className="mt-10">{<RankHistory></RankHistory>}</div>
			</div>
		</>
	);
};

export default Results;

import { Trans, useTranslation } from "react-i18next";
import Banner from "../components/Banner";
import Marquee from "../components/Marquee";
import Organizers from "../components/Organizers";
import Shtork from "../components/Shtork";
import { Link } from "react-router-dom";
import { RiArrowRightLine, RiUserStarLine } from "react-icons/ri";
import Title from "../components/Title";
import Watermark from "../components/Watermark";
import Starts from "../components/Until";
import Partners from "../components/Partners";
import Sponsors from "../components/Sponsors";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div className="relative">
			<Watermark></Watermark>
			<div className="relative z-10">
				<Marquee></Marquee>
				<Banner></Banner>
			</div>
			<Starts></Starts>
			<div className="pt-32 pb-16 text-center text-xl text-blue-100 bg-white">
				<div className="w-full md:w-2/3 mx-auto">{t("label.catches")}</div>
			</div>
			<div className="bg-white py-16">
				<Title>{t("titles.partners")}</Title>
				<Organizers></Organizers>
			</div>
			<div className="pt-16 pb-5 bg-white">
				<Title>{t("titles.invitation")}</Title>
				<div className="block lg:flex flex-wrap justify-center items-center gap-5 relative z-10">
					<div className="flex-1">
						<img
							className="w-full mx-auto"
							src="img/web_home2_1.png"
							alt="MSFF President"
						/>
					</div>
					<div className="flex-1 relative p-5">
						<Trans t={t}>invitation</Trans>
					</div>
				</div>
			</div>
			<div className="flex flex-wrap items-center gap-5 py-16 px-5 text-blue-100 bg-white text-center justify-around border-b border-t border-blue-100 relative z-10">
				<div>
					<div className="text-5xl">10</div>
					<div>{t("stats.team")}</div>
				</div>
				<div>
					<div className="text-5xl">111</div>
					<div>{t("stats.competitors")}</div>
				</div>
				<div>
					<div className="text-5xl">235</div>
					<div>{t("stats.participants")}</div>
				</div>
				<Link to="/teams" className="link">
					<RiUserStarLine className="text-2xl mr-2"></RiUserStarLine>
					{t("label.team")}
				</Link>
			</div>
			<div className="px-5 py-32 bg-white">
				<Sponsors></Sponsors>
				<div className="p-12"></div>
				<Partners></Partners>
			</div>
			{/* <Shtork></Shtork> */}
			<div className="pt-16 pb-5 bg-white relative z-10">
				<Title>{t("titles.venue")}</Title>
				<div className="block lg:flex flex-wrap justify-center items-center gap-5">
					<div className="flex-1 flex flex-col relative p-5 text-right">
						<Trans t={t}>venue</Trans>
						<br />
						<br />
						<Link to="/about" className="link self-end">
							{t("label.more")}
							<RiArrowRightLine className="text-2xl ml-2"></RiArrowRightLine>
						</Link>
					</div>
					<div className="flex-1">
						<img
							className="mx-auto"
							src="img/kharkhorum.jpeg"
							alt="KharKhorum"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;

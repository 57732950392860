import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./i18n/config";
import "./index.css";

import About from "./pages/About";
import Agenda from "./pages/Agenda";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Media from "./pages/Media";
import Results from "./pages/Results";
import Teams from "./pages/Teams";
import FAQ from "./pages/FAQ";

import Loader from "./pages/Loader";
import NotFound from "./pages/NotFound";

import MainLayout from "./layouts/MainLayout";
import Supporters from "./pages/Supporters";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<React.StrictMode>
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route path="/" element={<Navigate to="/home" />} />

					<Route path="/" element={<MainLayout />}>
						<Route path="/home" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/agenda" element={<Agenda />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/home" element={<Home />} />
						<Route path="/media" element={<Media />} />
						<Route path="/results" element={<Results />} />
						<Route path="/teams" element={<Teams />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/supporters" element={<Supporters />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Suspense>
		</React.StrictMode>
	</BrowserRouter>
);

import { useState } from "react";
import LanguageSwitch from "../i18n/Switch";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RiMenuFill } from "react-icons/ri";
import Pulse from "../components/Pulse";

const ITEMS = [
	"home",
	"agenda",
	"teams",
	"results",
	"about",
	"media",
	"faq",
	"contact",
	"supporters",
];

const HIHGLIGHTS = {
	results: () => "bg-green-500",
	media: () => "bg-red-100",
};

const HeaderMenu = () => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			{open && (
				<div
					className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 z-50"
					onClick={() => {
						setOpen(false);
					}}
				>
					<div className="bg-white w-72 h-full overflow-y-scroll">
						<div className="flex p-2 text-blue-100 border-b font-bold">
							<div>
								<div className="uppercase">{t("title")}</div>
								<div className="text-xs">{t("subtitle")}</div>
							</div>
						</div>
						<div className="flex flex-col text-sm">
							{ITEMS.map((item) => (
								<NavLink
									key={item}
									to={item}
									className={({ isActive }) =>
										`mb-2 p-2 border-b ${isActive ? "border-red-100" : ""}`
									}
								>
									<div className="flex">
										<div className="relative">
											{t(`menu.${item}`)}{" "}
											{HIHGLIGHTS[item] && (
												<Pulse color={HIHGLIGHTS[item]()}></Pulse>
											)}
										</div>
									</div>
								</NavLink>
							))}
						</div>
					</div>
				</div>
			)}
			<div className="h-16 w-full bg-blue-100 fixed top-0 z-40 shadow-md">
				<div className="h-16 safe flex justify-between items-center text-white">
					<div
						className="flex lg:hidden p-2 cursor-pointer"
						onClick={() => {
							setOpen(true);
						}}
					>
						<RiMenuFill className="text-2xl"></RiMenuFill>
					</div>
					<div className="flex">
						<img
							className="h-10 mr-2 hidden lg:block"
							src="img/msff-ugii.png"
							alt=""
						/>
						<div>
							<div className="uppercase">{t("title")}</div>
							<div className="text-xs">{t("subtitle")}</div>
						</div>
					</div>
					<div className="hidden text-xs lg:flex justify-center items-center gap-5">
						{ITEMS.map((item) => (
							<NavLink
								key={item}
								to={item}
								className={({ isActive }) =>
									`relative py-1 ${isActive ? "border-b border-red-100" : ""}`
								}
							>
								{t(`menu.${item}`)}{" "}
								{HIHGLIGHTS[item] && <Pulse color={HIHGLIGHTS[item]()}></Pulse>}
							</NavLink>
						))}
					</div>
					<LanguageSwitch></LanguageSwitch>
				</div>
			</div>
		</>
	);
};

export default HeaderMenu;

import { useTranslation } from "react-i18next";
import Title from "../components/Title";
import Watermark from "../components/Watermark";
import data from "../data/supporters.json";
import data_ice from "../data/supporters_ice.json";
import ReactCountryFlag from "react-country-flag";
import Partners from "../components/Partners";
import Sponsors from "../components/Sponsors";

const Supporters = () => {
	const { t, i18n } = useTranslation();

	return (
		<div className="p-5 bg-white relative">
			<Watermark></Watermark>
			<div className="z-20">
				<div className="text-sm mt-8">
					<Title>{t("titles.sponsor")}</Title>
					<div className="px-5 py-24 bg-white">
						<Sponsors></Sponsors>
					</div>
					<Title>{t("titles.supporters")}</Title>
					<div className="px-5 py-16 bg-white">
						<Partners></Partners>
					</div>
					<div className="flex flex-col sm:hidden">
						{data.map(({ img, name, amount }, index) => {
							return (
								<div key={index} className="mb-12 pb-12 border-b">
									<img
										src={img || "/img/profile.png"}
										alt={name.us}
										className="w-full object-contain bg-white"
									/>
									<div className="text-center font-bold mt-2 text-base">
										{name[i18n.language]}
									</div>
									<div className="text-center text-lg">{amount}</div>
								</div>
							);
						})}
					</div>
					<div
						className="w-full lg:w-2/3 mx-auto overflow-x-auto hidden sm:block"
						style={{ minHeight: 500 }}
					>
						<table className="table-fixed w-full result mx-auto relative z-10">
							<thead className="bg-white sticky top-0">
								<tr>
									<th className="w-16">#</th>
									<th className="text-left w-96">{t("label.name")}</th>
									<th className="text-center w-16">{t("label.origin")}</th>
									<th className="w-24">{t("label.amount")}</th>
								</tr>
							</thead>
							<tbody>
								{data.map(({ img, name, amount, flag = "mn" }, index) => {
									return (
										<tr
											key={index}
											className={
												index % 2 === 0 ? "bg-gray-600 bg-opacity-5" : ""
											}
										>
											<td className="text-center">{index + 1}</td>
											<td className="flex items-center text-base">
												<img
													src={img || "/img/profile.png"}
													alt={name.us}
													className="w-32 object-contain mr-2 bg-white"
													style={{ minHeight: 128 }}
												/>
												{name[i18n.language]}
											</td>
											<td className="text-center">
												<ReactCountryFlag
													svg={true}
													countryCode={flag}
													style={{ fontSize: 30, height: "auto" }}
													className="border-none"
												/>
											</td>
											<td className="text-center">{amount}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="my-16">
						<Title>{t("titles.ice_sponsor")}</Title>
					</div>
					<div className="flex flex-col sm:hidden">
						{data_ice.map(({ img, name, amount }, index) => {
							return (
								<div key={index} className="mb-12 pb-12 border-b">
									<img
										src={img || "/img/profile.png"}
										alt={name.us}
										className="w-full object-contain bg-white"
									/>
									<div className="text-center font-bold mt-2 text-base">
										{name[i18n.language]}
									</div>
									<div className="text-center text-lg">{amount}</div>
								</div>
							);
						})}
					</div>
					<div
						className="w-full lg:w-2/3 mx-auto overflow-x-auto hidden sm:block"
						style={{ minHeight: 500 }}
					>
						<table className="table-fixed w-full result mx-auto relative z-10">
							<thead className="bg-white sticky top-0">
								<tr>
									<th className="w-16">#</th>
									<th className="text-left w-96">{t("label.name")}</th>
									<th className="text-center w-16">{t("label.origin")}</th>
									<th className="w-24">{t("label.amount")}</th>
								</tr>
							</thead>
							<tbody>
								{data_ice.map(({ img, name, amount, flag = "mn" }, index) => {
									return (
										<tr
											key={index}
											className={
												index % 2 === 0 ? "bg-gray-600 bg-opacity-5" : ""
											}
										>
											<td className="text-center">{index + 1}</td>
											<td className="flex items-center text-base">
												<img
													src={img || "/img/profile.png"}
													alt={name.us}
													className="w-32 object-contain mr-2 bg-white"
													style={{ minHeight: 128 }}
												/>
												{name[i18n.language]}
											</td>
											<td className="text-center">
												<ReactCountryFlag
													svg={true}
													countryCode={flag}
													style={{ fontSize: 30, height: "auto" }}
													className="border-none"
												/>
											</td>
											<td className="text-center">{amount}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="text-xl text-blue-100 text-center my-10">
						{t("label.thank_you")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Supporters;

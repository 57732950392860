const Banner = ({ src = "img/banner.svg" }) => {
	return (
		<div className="bg-white">
			<img
				className="w-full object-contain"
				style={{ minHeight: 300 }}
				src={src}
				alt="Banner"
			/>
		</div>
	);
};

export default Banner;

const PARTNERS = {
	"apu.png": 80,
	"mak.svg": 180,
};

const Sponsors = () => {
	return (
		<div
			className="flex flex-col flex-wrap justify-center items-center gap-12 px-5 mx-auto"
			// style={{ maxWidth: 800 }}
		>
			{Object.entries(PARTNERS).map(([key, height]) => (
				<img
					key={key}
					className="w-auto object-contain"
					style={{ height }}
					src={`img/partners/${key}`}
					alt={key}
				/>
			))}
		</div>
	);
};

export default Sponsors;

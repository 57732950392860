import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import HeaderMenu from "./HeaderMenu";
import { useEffect } from "react";

const MainLayout = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location]);

	return (
		<>
			<HeaderMenu></HeaderMenu>
			<div className="pt-16">
				<div className="safe">
					<Outlet></Outlet>
				</div>
			</div>
			<Footer></Footer>
		</>
	);
};

export default MainLayout;

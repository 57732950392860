import Title from "../components/Title";
import { useTranslation } from "react-i18next";
import data from "../data/teams.json";
import Guide from "../components/Guide";
import { PHONE, EMAIL, WEB } from "../defines";
import { RiPhoneLine } from "react-icons/ri";
import Watermark from "../components/Watermark";

const EMERGENCIES = [
	{ name: "fire", phone: 101 },
	{ name: "police", phone: 102 },
	{ name: "ambulance", phone: 103 },
	{ name: "emergency", phone: 105 },
	{ name: "immigration", phone: "1800-1882" },
	{ name: "msff", phone: PHONE },
];

const Contact = () => {
	const { t } = useTranslation();

	return (
		<div className="p-5 bg-white relative">
			<Watermark></Watermark>
			<div className="relative z-10 mt-8">
				<Title justify="left">{t("menu.contact")}</Title>
				<div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
					<div className="text-sm">
						<div>
							{t("contact.phone")}:{" "}
							<a
								className="underline"
								target="_blank"
								rel="noreferrer"
								href={`tel:${PHONE}`}
							>
								{PHONE}
							</a>
						</div>
						<div>
							{t("contact.web")}:{" "}
							<a
								className="underline"
								target="_blank"
								rel="noreferrer"
								href={`${WEB}`}
							>
								{WEB}
							</a>
						</div>
						<div className="mb-2">
							{t("contact.email")}:{" "}
							<a
								className="underline"
								target="_blank"
								rel="noreferrer"
								href={`mailto:${EMAIL}`}
							>
								{EMAIL}
							</a>
						</div>
						<div className="w-64">{t("contact.address")}</div>
						<div className="mt-10 self-end">
							<div className="text-xl text-blue-100 mb-5">
								{t("titles.emergencies")}
							</div>
							{EMERGENCIES.map(({ name, phone }) => (
								<div key={phone} className="flex flex-wrap md:mb-2 mb-5">
									<div className="md:w-64 w-full uppercase">
										{t(`emergency.${name}`)}
									</div>
									<div className="flex">
										<RiPhoneLine className="mr-2 text-lg text-blue-100"></RiPhoneLine>
										<a
											target="_blank"
											rel="noreferrer"
											href={`tel:${phone}`}
											className="underline"
										>
											{t("label.call")}: {phone}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
					<iframe
						title="MSFF"
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13513.809559950874!2d106.910916!3d47.899652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693b6677828fd%3A0xe22f9c433bd9f03!2z0JzQvtC90LPQvtC70YvQvSDSrtC90LTRjdGB0L3QuNC5INCe0LvQuNC80L_QuNC50L0g0KXQvtGA0L7Qvg!5e1!3m2!1smn!2smn!4v1693888794601!5m2!1smn!2smn"
						width="100%"
						height="400"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
				<div className="p-8"></div>
				<Title justify="left">{t("label.guides")}</Title>
				<div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
					{data.map(({ flag, name, guides }, index) => {
						return guides.map((guide) => {
							return (
								<div key={index} className="p-5 shadow-md">
									<Guide {...guide} flag={flag} team={name}></Guide>
								</div>
							);
						});
					})}
				</div>
			</div>
		</div>
	);
};

export default Contact;

import Title from "../components/Title";
import { useTranslation } from "react-i18next";
import { RiDownload2Line, RiLink } from "react-icons/ri";
import Watermark from "../components/Watermark";

const RESOURCES = [
	{
		icon: <RiLink></RiLink>,
		name: `Visa information`,
		src: "https://en.consul.mn/visa/c/83",
	},
	{
		icon: <RiLink></RiLink>,
		name: `OFFICIAL F.I.P.S.e.d. RULES
		OF INTERNATIONAL “FOR NATIONS” COMPETITIONS
		“ICE FISHING”`,
		src: "https://www.fips-ed.com/wp-content/uploads/2023/08/Reg_FIPSed_Ice_2023_en_def.pdf",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: "Official logo (PDF)",
		src: "/img/WIFC20.pdf",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: "Official logo (PNG white background)",
		src: "/img/20th_wifc_logo.png",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: "Official logo (PNG transparent background)",
		src: "/img/20th_wifc_logo_transparent.png",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Drill quotation`,
		src: "/resources/DRILL.pdf",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Feed quotation`,
		src: "/resources/FEEDER.pdf",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Inscription form A`,
		src: "/resources/Inscription-form_A.docx",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Inscription form B`,
		src: "/resources/Inscription-form_B.docx",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Inscription form C`,
		src: "/resources/Inscription-form_C.docx",
	},
	{
		icon: <RiDownload2Line></RiDownload2Line>,
		downloadable: true,
		name: `Bait order form`,
		src: "/resources/Bait-order-2024.docx",
	},
];

const QUESTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const Contact = () => {
	const { t } = useTranslation();

	return (
		<div className="p-5 bg-white relative">
			<Watermark></Watermark>
			<div className="relative z-10 mt-8">
				<Title justify="left">{t("titles.resources")}</Title>
				<div className="text-sm">
					{RESOURCES.map(({ name, src, icon, downloadable }) => (
						<div key={name} className="flex mb-2">
							<div className="mr-2 text-lg text-blue-100 flex-shrink-0">
								{icon}
							</div>
							<a
								target="_blank"
								rel="noreferrer"
								href={src}
								className="underline"
							>
								{downloadable ? `${t("label.download")}: ${name}` : name}
							</a>
						</div>
					))}
				</div>
				<div className="p-8"></div>
				<Title justify="left">{t("titles.faq")}</Title>
				<div className="text-sm">
					{QUESTIONS.map((number) => (
						<div key={number} className="mb-10">
							<div className="text-xl text-blue-100">
								{t(`faq.${number}_q`)}
							</div>
							<div>{t(`faq.${number}_a`)}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Contact;

import { useTranslation } from "react-i18next";
import Title from "../components/Title";
import Watermark from "../components/Watermark";
import data from "../data/teams.json";
import ReactCountryFlag from "react-country-flag";
import Guide from "../components/Guide";
import Pulse from "../components/Pulse";

const Member = ({ name, role, img }) => {
	return (
		<div key={name} className="w-32">
			<img
				className="h-32 w-full object-cover mb-2"
				src={img || "img/profile.png"}
				alt={name}
			/>
			<div className="text-xs text-center">{role}</div>
			<div className="text-sm text-center">{name}</div>
		</div>
	);
};

const MONGOLA = [
	{ name: "Erdenechuluun Tumurpurev", role: "Captain" },
	{ name: "Ganzorig Sharkhuu", role: "Head Coach" },
	{ name: "Chinbat Namnandorj", role: "Competitor" },
	{ name: "Zorigtbaatar Gantumur", role: "Competitor" },
	{ name: "Munkhtur Jargalsaikhan", role: "Competitor" },
	{ name: "Balkhuu Baldanbuu", role: "Competitor" },
	{ name: "Munkhtur Byambaa", role: "Competitor" },
	{ name: "Ankhbayar  Bolormaa", role: "Reserve" },
	{ name: "Ariunbayar Noov", role: "Reserve" },
	{ name: "Batsaikhan Radnaasambuu", role: "Assistant coach" },
	{ name: "Khishigbayar Badarch", role: "Delegate" },
	{ name: "Battulga Myagmarsuren", role: "Delegate" },
	{ name: "Nyamkhuu Erdenechuluun", role: "Delegate" },
	{ name: "Altangerel Igori", role: "Delegate" },
];

const Teams = () => {
	const { t } = useTranslation();

	return (
		<div className="p-5 bg-white relative">
			<Watermark></Watermark>
			<div className="relative z-20 mt-8">
				<div className="mb-10">
					<Title justify="left">
						ICE TEAM MONGOLIA
						<div className="flex">
							<div className="relative">
								(HOME TEAM)
								<Pulse color={"bg-green-500"}></Pulse>
							</div>
						</div>
					</Title>
					<img className="w-full" src="/img/mongol.png" />
					<div className="flex flex-wrap gap-5 flex-col sm:flex-row">
						<img className="mx-auto h-64 p-5" src="/img/script.svg" />
						<div className="flex-1 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-5">
							{MONGOLA.map(({ name, role }) => (
								<div className="flex items-start gap-2">
									<div className="shrink-0 shadow-md">
										<ReactCountryFlag
											svg={true}
											countryCode={"mn"}
											style={{ fontSize: 50, height: "auto" }}
										/>
									</div>
									<div className="text-xs">
										<div className="uppercase">{name}</div>
										{role}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="pt-8 pb-24 text-center text-xl text-blue-100">
					<img className="h-24 mb-5 mx-auto" src="/img/partners/ice.png" />
					<div>WARM GREETINGS FROM ICE TEAM MONGOLIA</div>
					<div className="text-4xl">WELCOME TO OUR HOMELAND</div>
				</div>
				{data.map(({ flag, name, members, guides, status, years }) => (
					<div
						key={flag}
						className={`z-10 mb-16 ${status ? "" : "opacity-50"}`}
					>
						<Title justify="left">
							ICE TEAM {name}
							<div className="flex">
								<div className="relative">
									({status || "pending"}){" "}
									<Pulse
										color={status ? "bg-green-500" : "bg-orange-500"}
									></Pulse>
								</div>
							</div>
						</Title>
						<div className="flex flex-wrap gap-5">
							<div className="flex-shrink-0 w-full md:w-48">
								<ReactCountryFlag
									svg={true}
									countryCode={flag}
									style={{ fontSize: 170, height: "auto" }}
									className="border shadow-md mr-2 md:mr-0"
								/>
								{years && (
									<div
										className="text-lg text-center my-2 text-blue-100"
										style={{ width: 170 }}
									>
										The Champion of {years.join(", ")}
									</div>
								)}
							</div>
							<div className="flex-1">
								<div className="text-gray-500 mb-2">
									{t("label.competitors")}
								</div>
								<div className="flex flex-wrap gap-5 mb-10">
									{members.map((member, index) => (
										<Member key={index} {...member}></Member>
									))}
								</div>
								<div className="text-gray-500 mb-2">{t("label.guides")}</div>
								<div className="flex gap-5">
									{guides.map((guide, index) => (
										<Guide key={index} {...guide}></Guide>
									))}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Teams;

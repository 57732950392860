import ReactCountdown from "react-countdown";

const Countdown = ({
	day = "Хоног",
	hour = "Цаг",
	minute = "Минут",
	second = "Секунд",
	counterItemClass = "text-5xl",
}) => {
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return null;
		} else {
			return (
				<div className="flex gap-5 justify-center items-end text-center">
					<div className="flex-1">
						<div className={counterItemClass}>{days}</div>
						<div className="">{day}</div>
					</div>
					<div className="flex-1">
						<div className={counterItemClass}>{hours}</div>
						<div className="">{hour}</div>
					</div>
					<div className="flex-1">
						<div className={counterItemClass}>{minutes}</div>
						<div className="">{minute}</div>
					</div>
					<div className="flex-1">
						<div className={counterItemClass}>{seconds}</div>
						<div className="">{second}</div>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="text-xl text-center md:text-right">2024-03-09 10:50</div>
	);

	return (
		<div>
			<ReactCountdown date={new Date("2024-03-09 10:50")} renderer={renderer} />
		</div>
	);
};

export default Countdown;

const Pulse = ({ color = "bg-red-100" }) => {
	return (
		<span className="flex h-2 w-2 ml-2 absolute top-0 -right-2">
			<span
				className={`animate-ping absolute inline-flex h-3 w-3 -mt-1 -ml-1 rounded-full ${color} opacity-75`}
			></span>
			<span
				className={`relative inline-flex rounded-full h-1 w-1 ${color}`}
			></span>
		</span>
	);
};

export default Pulse;

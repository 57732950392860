const TEXT_ALIGN = {
	center: "text-center",
	left: "text-left",
	right: "text-right",
};

const BORDER_ALIGN = {
	center: "mx-auto",
	left: "ml-0",
	right: "ml-auto mr-0",
};

const Title = ({
	children,
	color = "text-blue-100",
	borderColor = "border-blue-100",
	justify = "center",
	fontSize = "text-2xl",
}) => {
	return (
		<div className="mb-5 relative">
			<div className={`${color} ${TEXT_ALIGN[justify]} ${fontSize} uppercase`}>
				{children}
			</div>
			<div
				className={`h-2 absolute border-b-2 w-32 ${borderColor} ${BORDER_ALIGN[justify]}`}
			></div>
			<div className={`h-2 border-b border-red-100`}></div>
		</div>
	);
};

export default Title;

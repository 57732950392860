import { useTranslation } from "react-i18next";
import Title from "../components/Title";
import Watermark from "../components/Watermark";
import data from "../data/agenda.json";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { DEMO } from "../defines";
import Starts from "../components/Until";

const Agenda = () => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;

	const [current, setCurrent] = useState();
	const match = DEMO ? new Date("2024-03-09 09:50") : new Date();

	const checkPast = (day, time) => {
		if (!day || !time) return null;

		return new Date(`${day} ${time}`) < match;
	};

	useEffect(() => {
		let d;
		for (const [dayIndex, { day, events }] of data.entries()) {
			if (events) {
				for (const [timeIndex, { start }] of events.entries()) {
					if (day && start) {
						const date = new Date(`${day} ${start}`);
						if (date > match && !d) {
							d = { day: dayIndex, time: timeIndex };
						}
					}
				}
			}
		}

		setCurrent(d);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Starts showLink={false}></Starts>
			<div className="p-5 bg-white relative">
				<Watermark></Watermark>
				<div className="w-full lg:w-2/3 mx-auto mt-8">
					<Title justify="left">{t("menu.agenda")}</Title>
					<div className="relative z-10">
						{data.map(({ day, label, events }, dayIndex) => (
							<div
								key={day}
								className="border-l border-blue-100 pl-2 pb-8 text-sm"
							>
								<div className="flex">
									<div className="text-blue-100 w-32 flex-shrink-0">{day}</div>
									{label && (
										<div className="text-gray-400 underline">
											{label[language]}
										</div>
									)}
								</div>
								<div className="mx-5">
									{events &&
										events.map(
											({ label, start, end, children = [] }, timeIndex) => {
												const isPast = checkPast(day, start);

												return (
													<div key={timeIndex} className="flex my-2 relative">
														<div className="absolute left-0 top-0 -ml-9 bg-white">
															{isPast === true ? (
																<RiCheckboxCircleLine className="text-lg text-blue-100"></RiCheckboxCircleLine>
															) : null}
														</div>
														<div className="w-32 flex-shrink-0 relative">
															{current &&
																current.day === dayIndex &&
																current.time === timeIndex && (
																	<span className="flex h-2 w-2 ml-2 absolute top-1 -left-10">
																		<span
																			className={`animate-ping absolute inline-flex h-4 w-4 -mt-1 -ml-1 rounded-full bg-red-100 opacity-75`}
																		></span>
																		<span
																			className={`relative inline-flex rounded-full h-2 w-2 bg-red-100`}
																		></span>
																	</span>
																)}
															{start} {end ? ` - ${end}` : ""}
														</div>
														<div
															className={`-ml-5 ${
																!!isPast || isPast === null
																	? "text-gray-400"
																	: ""
															}`}
														>
															<div>{label[language]}</div>

															{children.length > 0 && (
																<div className="border-l-2 p-2">
																	{children.map((action, index) => (
																		<div
																			key={index}
																			className="text-xs mb-2 text-gray-500"
																		>
																			{action.start} {action.label[language]}
																		</div>
																	))}
																</div>
															)}
														</div>
													</div>
												);
											}
										)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Agenda;
